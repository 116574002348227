/* You can add global styles to this file, and also import other style files */
.lec-inner-panel {
	margin-top: -15px!important;

    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bg-brand {
	background:#a4442f
}
.app-sidebar .navigation li a {
	white-space: pre-wrap!important;
}
.app-sidebar .navigation li:hover > a, .off-canvas-sidebar .navigation li:hover > a {
	background:#cf132b!important;
	color:#FFF!important
}
.nav-container ul li.active a span {
	color:#a4442f!important
}
.nav-container ul li.active:hover > a span, .off-canvas-sidebar .navigation li,active:hover > a span {
	color:#FFFFFF!important
}
.app-sidebar .navigation li:hover > a i, .off-canvas-sidebar .navigation li:hover > a i {
	color:#FFF!important
}
.app-sidebar .navigation li > a:before, .off-canvas-sidebar .navigation li > a:before {
	border-right: solid 5px #a4442f!important;
}
div.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: -310px;
	padding: 20px;
	font-size: 20px;
	z-index: 9;
}
.course-hero-panel .jumbotron {
	border-bottom:4px solid #a4442f;
}
.search-course-panel-bg {
	background: url('assets/img/search-course-panel-bg.jpg');
	background-repeat: no-repeat;
	background-size:cover;
	background-position:right center;
}

p.ctitle {
	white-space:break-spaces!important;
	color: #a4442f!important;
    text-align: center!important;
    text-transform: capitalize!important;
    margin-top: 30px!important;
    border-bottom: 1px solid #a4442f!important;
    padding-bottom: 30px!important;
    margin-left: 0!important;
    margin-right: 0!important;
}
.evaluation-modal .table td {
	padding: 0.25rem 0.90rem!important;
    font-size: 0.90rem!important;
    font-weight: 500;
}
.evaluation-modal .table tr:first-child td {
	padding: 0.75rem!important;
}
.announcement-icon {
	font-size: 2rem;
	vertical-align: middle;
	float: left;
	margin-right: 0.5rem;
}
.claimed-box .media img{
	width: 54px;
	margin: 0!important;
}
.claimed-box .media-body p {
	font-size: 1rem!important;
    white-space: break-spaces;
    text-transform: capitalize!important;
    font-weight: 500!important;
    padding: 0!important;
    margin: 0 1rem!important;
}


.original_price {
    display: inline-block;
    margin-right: 7px;
    text-decoration: line-through;
}

/*open cart css*/


.cart_link {
    color: #000;
    font-size: 15px!important;

}
.cart_ul{
    padding: 0;
    list-style: none;
}
.cart_info {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.item_name {
    font-size: 14px;
    margin: 0 10px 10px;
}
.qty_section {
    display: flex;
    flex-direction: row;
    border-radius: 20px 20px 20px 20px;
}

.qty_section button {
    background-color: transparent;
    border: 0;
}
.qty_section input{
    width: 25%;
    height: 40px;
    text-align: center;
}

.remove_cart_item {
    display: flex;
    margin-right: 15px;
    margin-top: 10px;
    align-items: center;
}

.remove_cart_item .price{
    flex: 1 1;
    font-size: 15px;
    margin: 0 0px 0px 0px;
    padding-top: 0px;
}
.reveal-cart li a{
    color: #333132;
}
.by-btn {
    text-align: center;
}

.by-btn a {
    font-size: 16px;
    font-weight: 700;
    background: #83ac2b;
    display: inline-block;
    color: #fff;
    padding: 10px 15px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .05rem;
}

.navbar-side-new h2{
  margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
}

.fa-trash-alt{
    font-size: 13px;
}

.empty_cart {
    margin: 100px 0;
}

.cartitem {
    display: flex;
    position: absolute;
    color: #fff;
    background-color: #83ac2b;
    font-size: 9px;
    border-radius: 50%;
    top: -10px;
    right: -6px;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
}

.navbar-side-new {
    height: 100%;
    width: 20%;
    position: fixed;
    top: 0;
    right: -9999px;
    padding: 10px;
    list-style: none;
    background: #fff;
    overflow-y: scroll;
    z-index: 1000;
    transform: translateX(100%);
    transition: .3s ease;
}

.text-right {
    text-align: right;
}

.reveal-cart {
    transform: translateX(0);
    transition: .3s ease;
    right: 0;
    background: #fff;
    box-shadow: 7px -1px 12px 0 rgb(0 0 0 / 15%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
}

/*end*/

/* Mohit add responsive css */
.wrapper.nav-collapsed .main-panel .main-content{
    padding-bottom: 120px;
}
/* XS Screen */
@media screen and (max-width:575px) {
    .lecutes h4{
        font-size: 1rem !important;
	}
	.lec_options a {
	    border: 1px solid #888;
	    padding: 2px 12px;
	    margin-bottom: 10px;
	    display: inline-block;
	    font-size: 13px;
	    border-radius: 30px;
	}
	.notes-panel{
		width: 100% !important;
		z-index: 999 !important;
	}
	vg-controls{
		height: 45px !important;
		z-index: 0!important;
	}
	vg-scrub-bar{
		z-index: 0!important;
	}

}
/* End */
