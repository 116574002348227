@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/themes/prism-okaidia";
@import "scss/app-variables";
@import "scss/mixins";
@import "scss/core/palette";
@import "scss/custom-bootstrap";
@import "scss/custom-bootstrap-rtl";
@import "scss/core";
@import "scss/plugins";
@import '~ngx-ui-switch/ui-switch.component.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "scss/demo"; // Remove this SCSS from production

body {
    font-weight: 400;
}
.form-control:focus,.btn:focus {
    box-shadow: none!important
}
.form-control:not(.round) {
    min-height: 40px;
    border-radius: 0!important;
}
.profile-menu li {
    border-right: 1px solid #FFFFFF25;
}
.profile-menu li:last-child {
    border-right: none!important;
}

.w-20 {
    width: 20%!important
}
.profile-menu .tooltip-inner {
    background-color: #00227c;
    min-width: 200px;
}
.profile-menu .tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #00227c !important;
}
.profile-menu .tooltip.bs-tooltip-left .arrow:before {
    border-right-color: #00227c !important;
}
.profile-menu .tooltip.bs-tooltip-bottom .arrow:before {
    border-right-color: #00227c !important;
}
.profile-menu .tooltip.bs-tooltip-top .arrow:before {
    border-right-color: #00227c !important;
}
.empty-s:after {
    content:'Not Specified';
    font-style: italic;
    color:#b7b7b7;
    font-size: 12px;
}
.nav-container ul li.active a span {
    color:#00227c!important;
}
.p-items-list li {
    border-bottom: 1px solid #6c757d40;
    padding-bottom: 10px;
    line-height: 24px;
}


@media (min-width: 576px){
    .modal-dialog {
        max-width: 55%;
        margin: 1.75rem auto;
    }
}

label.required::after {
    content: "*";
    color:#ff586b;
    font-size:15px;
    font-weight: 100;
    top:0
}

.toast-container p {
    margin-bottom: 0;
}
.ng5-slider .ng5-slider-bubble.ng5-slider-model-value {
    color:#00227c!important;
    font-weight: 500!important
}
.ng5-slider .ng5-slider-pointer {
    background-color: #00227c!important;
}
.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background-color: #FFFFFF!important
}
.db-en-courses .course-item .activity-list li {
	text-align:center
}
.db-en-courses .course-item .activity-list li p {
    text-transform: uppercase;
    font-size: 12px;
    color: #333333;
}
.db-en-courses .course-item .activity-list li a{    
    display: inline-block;
    border-radius: 50%;
    padding: 12px;
    border:2px solid
}
.db-en-courses .course-item .activity-list li a i{    
	color: #00227c!important;
}
.db-en-courses .course-item .activity-list li a:hover{    
    display: inline-block;
    border-radius: 50%;
    padding: 12px;
    background: #00227c;
	border: 2px solid #00227c;
	transition: .3s;
}
.db-en-courses .course-item .activity-list li a:hover i{    
	color: #FFFFFF!important;
}
.h-200 {
    height:200px
}
.bg-center {
    background-position: center!important;
}
.tr-10-15 {
    top:-15px;
    right: -10px;
}
.payment-list .card-body {
    padding-left: 0!important;
    padding-right: 0!important;
}
.checkout-panel {
    position: fixed;
    right: 0;
    top: 0;
    min-height: 80vh;
    overflow-y: auto;
    width: 0;
    z-index: 9999!important;
    background: #FFF;
    // margin-right: -300px;
    display: none;
    transition: width 1s;
    transition: margin 1s;
    background: #FFFFFF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.show_panel {
    width: 300px;
    display: block;
}
.hide_panel {
    width: 0;
}

.downloads button:hover,.downloads button:active,.downloads button:focus{
    text-decoration: none;
}

.downloads button{
    width: 100%;
    text-align: left;
}
#taskboard .card {
    cursor: grab;
}
.q-board p.lead p {
	margin:0!important;
	font-size: 1.25rem!important;
}
.email-application .email-app-menu .list-group-messages a {
    padding: 0.5rem 1rem;
    font-size: 1.05rem;
}
.exam-date {
	cursor:pointer;
}
.exam-date:hover {
	background:#00227c;
}
.exam-date:hover p{
	color:#FFFFFF;
}
.webinar-body .popover {
	min-width:90%!important;
	top:-15px!important;
}
.webinar-body .popover-body {
	background: #4b4b4b!important;
}
.webinar-body .popover-header {
	border-color:#ffffff5c!important
}

.webinar-body .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 2px!important;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgb(32, 111, 174)!important;
}
.webinar-body .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px!important;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #00227c!important;
}
